<template>
    <b-form-group>
      <multiselect
        id="healthPlan"
        v-model="clinicHealthPlan"
        :options="healthPlansOptions"
        :option-height="40"
        :showLabels="false"
        :searchable="true"
        :internalSearch="false"
        :allowEmpty="true"
        :showNoResults="true"
        placeholder="Buscar convênio"
        class="with-border"
        @select="selectClinicHealthPlan"
        @search-change="handleSearch"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown v-if="!clinicHealthPlan"/>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          {{ props?.option?.label }}
        </template>
        <template slot="singleLabel" slot-scope="props">
          <Ellipsis>
            {{ props?.option?.label }}
          </Ellipsis>
        </template>
        <template slot="noOptions"> Nenhuma opção </template>
      </multiselect>
      <Close
        v-if="clinicHealthPlan"
        class="close-item-icon"
        :class="{ 'diferent-top': diferentTop }"
        @click="clearHealthPlan"
      />
    </b-form-group>
  </template>
  
  <script>
  import { getCurrentClinic } from '@/utils/localStorageManager'
  
  export default {
    name: 'ClinicHealthPlanSelect',
    components: {
      ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
      Close: () => import('@/assets/icons/close.svg'),
      Ellipsis: () => import('@/components/General/Ellipsis')
    },
    props: {
      selectClinicHealthPlan: Function,
      diferentTop: { type: Boolean, default: false }
    },
    data() {
      return {
        clinicHealthPlan: null,
        clinicHealthPlans: [],
        clinic: getCurrentClinic(),
        search: null
      }
    },
    async created() {
      await this.getClinicHealthPlans()
    },
    computed: {
      healthPlansOptions() {
        if(!this.search) return this.clinicHealthPlans
        return this.clinicHealthPlans.filter(clinicHealthPlan => clinicHealthPlan?.label?.toLowerCase().includes(this.search?.toLowerCase()))
      },
    },
    methods: {
      async getClinicHealthPlans() {
        try {
          const { data } = await this.api.getAllClinicHealthPlans(this.clinic.id)
          this.clinicHealthPlans = data.map(clinicHealthPlan => ({
            label: clinicHealthPlan?.health_plan?.fantasy_name,
            value: clinicHealthPlan?.health_plan?.id
          })).sort((a , b) => {
            if(a.label < b.label) return -1;
            else return 1
          })
        } catch (error) {
          this.$toast.error('Erro ao buscar planos de saúde')
        }
      },
      handleSearch(query){
        this.search = query
      },
      clearHealthPlan(){
        this.clinicHealthPlan = null
        this.selectClinicHealthPlan(null)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .close-item-icon{
      width: 25px;
      height: 25px;
      fill: rgb(127, 145, 210);
      position: absolute;
      right: 15px;
      top: 40%;
    }
  
    .diferent-top{
      top: 32% !important;
    }
  </style>
  